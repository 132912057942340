











import { Component, Vue } from 'vue-property-decorator'

import EventStudyReviewerForm from '@/partials/forms/Events/StudyReviewerForm.vue'

@Component({
  components: {
    EventStudyReviewerForm
  }
})
export default class EventStudyReviewerCreate extends Vue {

}
